import emoji from "react-easy-emoji";

/* 1. Main App */
/* 2. Social Networks */
/* 3. Banner Section  */
/* 4. About Section  */
/* 5. Skills Section */
/* 6. Open Source Section  */
/* 7. Projects Section */
/* 8. Experience Section */
/* 9. Contact Section */

/* wrap your text in emoji("") if you want to show emoji in your text

/*=====================
    1. Main App
    Desc: set what's in the document's head section
==========================*/

const app = {
  title: "Kaal Group",
  // Note : consider leaving null if you dont have any head icon
  icon: "kaal.png",
  description: "We are mumbai based fast growing company.",
};

/*=====================
    2. Social Networks 
    Desc: your social network links
==========================*/

const socialNetworks = {
  //Note : if you dont need or have any social network just add null value
  github: "https://github.com/",
  linkden: null,
  facebook: "https://www.facebook.com/areebsayyed0",
  instagram: "https://www.instagram.com/kaalgroup/",
  twitter: null,
};

/*=====================
    3. Banner Section
    Desc: techfolio logo, greetings, summary and avatar,
==========================*/

const bannerSection = {
  username: "Kaal Group",
  bannerHeading: "Areeb Sayyed",
  bannerTagLine: emoji(
    "Hi, I am the Founder & Owner of KAAL and all Sub Companies of Kaal. \n Kaal Group is a fast-growing Mumbai based company, we work on Android Application, Web Application, E-commerce Websites & More.. The Company's solutions enable organizations to solve their IT related business need through applications and services, we provide a  suite of IT facilities like Apps, Website And Services."
  ),
  //Note:Simply add you avatar image in src/assets/imgs folder 
  //and add the name along with extention e.g xyz.png , abc.svg
  //Note : if you want to use default illustration instead of adding your avatar add defaultAvatar.svg
  bannerAvatar: "avatar.jpeg",
  resumeLink:
    "",
};

/*=====================
    4. About Section
    Desc: It's all about you!. this section is designed using the command 
    line concept so it asked you to add the admin of the command line 
    and 2 basic commands (i recommend you to use default commands).
==========================*/

const aboutSection = {
  admin: "kaal@areebsayyed",
  firstCommand: " sudo run about.exe",
  secondCommand: " executing about.exe",

  about:
    "Kaal Group is a provider of mobile and online web based business solutions, We work on Mobile Apps, Web Apps, Ecommerce platform, Messaging platforms, technologies that powers online business of the brands .The Company's segments include mostly the Business sector. \n We have a Team of highly skilled and talented professionals who have vast experiences in major fields of technology Our team has the ability to provide the Clients with anytype services, solution and products related to IT",
};

/*=====================
    5. Skils Section
    Desc: This section hep you set your skills 
==========================*/

const skillsSection = {
  // Recommended : don't add more than 3 skill taglines.
  skillsTagLines: [
    "Excitable towards giving breath to different mockup PSDs and web designs using Html / Css and Scss.",
    "Love to Build Scalable web and native application using React with the several stacks, packages, and services.",
    "Also Passionate towards other stuff like WordPress development, python scripting, and Dev-Ops.",
    "HTML5, CSS3, Bootstrap, Materialize, PHP, MYSQL for Basic Websites.",
    "JavaScript , HTML5, CSS3, Bootstrap 4, JQuery for Static Pages.",
    "React Js, Node Js, Mongo DB, Material UI, Express for Scalable Web Applications.",
    "We Also Work in Unity 3D, Unity 2D, Android Studio, React Native, for Android Application",
    "We Also Work in Python, Java, AI, AR, VR Technologies",

  ],

  // Recommended : don't add more than 12 skills.
  // Note : go to https://fontawesome.com/icons?d=gallery > select the icon you want to show 
  //        > copy its class > paste it as {fontAwesomeClassname: "fab fa-html5"} in SkillsSet

  skillsSet: [
    {
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      fontAwesomeClassname: "fab fa-js",
    },
    {
      fontAwesomeClassname: "fab fa-react",
    },
    {
      fontAwesomeClassname: "fab fa-node",
    },
    {
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      fontAwesomeClassname: "fas fa-fire",
    },
    {
      fontAwesomeClassname: "fab fa-wordpress",
    },
    {
      fontAwesomeClassname: "fab fa-python",
    },
    {
      fontAwesomeClassname: "fab fa-linux",
    },
    {
      fontAwesomeClassname: "fab fa-docker",
    },
  ],
};

/*=====================
    6. Open Source Section
    Desc: This section hlep you configure your github repos section 
==========================*/
const openSourceSection = {

  //Note :  don't edit the githubToken
  githubToken: process.env.REACT_APP_GITHUB_ACCESS_TOKEN,

  githubUserName: "",
  //Note :  no of open source projects you want to show.
  numberOfRepos: 0,
};

/*====================
   11 team
   dec:this is team section
========================*/

const projectTeams = {
  projects: [
    {
      projectName: "Areeb Sayyed",
      projectImg: "https://res.cloudinary.com/kaalgrouplnf/image/upload/v1626592094/socialbookimages/umm6bvvaystk70ddx2da.jpg",
      projectBio: "Hey I am Owner & Founder Of KAAL and all sub-companies of KAAL. I am fullstack developer and fashion model , i have experience in many programing languages & having experties in ReactJs, NodeJs, ReactNative, FireBase, MongoDB, Linux, Python, Ddos, PHP etc",
      projectUrl: "https://www.google.com/search?q=Areeb+Sayyed",
      socialinsta: "https://www.instagram.com/areebsayyed_/",
      socialyt: "https://www.youtube.com/channel/UCWoncQwtEWPRsRofAGimGeA",
      socialtw: "https://twitter.com/areeb_sayyed",

    },
    // {
    //   projectName: "Harshit Shukla",
    //   projectImg: "https://res.cloudinary.com/kaalgrouplnf/image/upload/v1626599104/socialbookimages/whwdny15kbefitcqq6vb.jpg",
    //   projectBio: "Hi I am Senior & Lead Developer Of Kaal Group & Co-Owner. I am fullstack developer having experties in ReactJs, NodeJs, ReactNative, FireBase, MongoDB, Linux Etc",
    //   projectUrl: "https://res.cloudinary.com/kaalgrouplnf/image/upload/v1626599104/socialbookimages/whwdny15kbefitcqq6vb.jpg",
    //   socialinsta: "https://www.instagram.com/harshitshukla___",
    //   socialyt: "https://www.youtube.com/channel/UCwYLJW8iL8JMdBKU3bsWq8w",
    //   socialtw: "https://www.twitter.com/harshshukla___",
    // },
    // {
    //   projectName: "Vedant Mahewar",
    //   projectImg: "https://res.cloudinary.com/kaalgrouplnf/image/upload/v1626592137/socialbookimages/dvc3zzwdjcqipoctn57l.jpg",
    //   projectBio: "Hey I am Co-Founder Of KAAL. I am Creative Head of Kaal Group",
    //   projectUrl: "https://res.cloudinary.com/kaalgrouplnf/image/upload/v1626592137/socialbookimages/dvc3zzwdjcqipoctn57l.jpg",
    //   socialinsta: "https://www.instagram.com/",
    //   socialyt: "https://www.youtube.com/",
    //   socialtw: "https://www.facebook.com/",
    // },


  ],
}


/*=====================
    7. Projects Section
    Desc: This section hlep you configure your major projects.
==========================*/

const projectsSection = {
  projects: [
    {
      projectName: "Eruda - Browser Console",
      projectImg: "https://play-lh.googleusercontent.com/VFpPqvK3qCIW7pe5uguBTvjQ1G4J_tll127umCFzv4lLLVSb-nzho4a-G_vecpQmqS4=s180-rw",
      projectBio:
        "App is for every guy who wants to feel the power of IT. Now anyone can control whole website with one click of a button, Edit Content without inspecting elements, Run Javascript functions & logs in console. check Dom Tree & elements & Network and snippets Etc.",

      projectUrl: "https://play.google.com/store/apps/details?id=com.eruda",
      stackList: ["TTS", "Java", "Native Java", "Material IO", "Firebase", "OneSignal"],
    },
    {
      projectName: "SocialBook",
      projectImg: "https://play-lh.googleusercontent.com/SnkylquIWyuXDhM0g04JqGkUM58Gbz_pZku06t8ymkB90uxgswovzGh-7Y4y8P4a6qKB=s180-rw",
      projectBio:
        "We have developed this application and which is a platform for readers and writers from all over the world .",

      projectUrl: "https://play.google.com/store/apps/details?id=com.kaal",
      stackList: ["React JS", "React Native", "Material UI", "Node Js", "Mongo DB", "OneSignal"],
    },
    {
      projectName: "Third Eye",
      projectImg: "https://play-lh.googleusercontent.com/QMC-6X4IPGGAXGcvlMLTZHQ_pCuPUvNGN3G2fb6sV5_jyBMdWAOM9L9Wv9ZlgT28cyw=s180-rw",
      projectBio: "Hey folks, introducing the 'Third Eye' as the name same says it's a public platform where the common civilians can report any kind of inconvenience, complaints or about public property damage The app can also be used by people to add reports about their daily struggles due to the shortcomings in the public services or To add any suggestions for the local municipal council.",

      projectUrl: "https://play.google.com/store/apps/details?id=com.publiceye",
      stackList: ["Java", "Native Java", "Kitten UI", "Firebase", "OneSignal"],
    },
    {
      projectName: "Reno Music Pro",
      projectImg: "https://play-lh.googleusercontent.com/E0XAr3625FAA8y0wHXlUVKq2ku6zqPdOWej3oPkcAUn_gchISvkwbhz2WoViKtLMRFo=s180-rw",
      projectBio:
        "The application can be tweaked according to your taste ,the app makes your music listening experience much richer with customizable themes, accent colors and easy navigation with a snappy user interface.",

      projectUrl: "https://m.apkpure.com/reno-music-pro-music-player/com.kaal.group.renomusic",
      stackList: ["Java", "Native Java", "Material IO", "Firebase", "OneSignal"],
    },
    {
      projectName: "Social Stories",
      projectImg: "https://play-lh.googleusercontent.com/px5_PkHbf72GK_qLIqBlfOV0KUJBycAq8GebqANNx9Xz9qZxoEWrY4GoO39fgnHp6w=s180-rw",
      projectBio:
        "Social stories are a fast and elegant way to save the story from all the major social media platforms This application helps you to easily save video & photo from Stories.With just one click, you can save the story directly into your device. ",

      projectUrl: "https://play.google.com/store/apps/details?id=com.kaalgroup.socialstories",
      stackList: ["Java", "Native Java", "Material IO", "Firebase", "OneSignal"],
    },

   
    
    {
      projectName: "Emart",
      projectImg: "https://play-lh.googleusercontent.com/ew25XU2FUG9ZeS5_ix6vZ7kzV6vbkKj-xpgVtAf3d0APCcjAMoJw50lMLWX6oMLs6Q=s180-rw",
      projectBio: "Hello from Kaal Group, here we are introducing a platform which contains all types of eCommerce platforms developed in the undertaking of Kaal Group, It's a one-stop for all different types of e-commerce platforms available in the market, do provide us with your valuable feedback",

      projectUrl: "https://play.google.com/store/apps/details?id=com.kaal.emart",
      stackList: ["TTS", "Java", "Native Java", "Material IO", "Firebase", "OneSignal"],
    },
    {
      projectName: "Tic Tac Toe",
      projectImg: "https://play-lh.googleusercontent.com/Ky-0gsDFoOPVQ1So479Ez_1fvT-SuCz--i6eR9_cFQ0FMjzw66P5WCt9WKXYadLbSPk=s180-rw",
      projectBio: "Tic Tac Toe is a two-player game, called X and O, who alternate marking spaces in 3 × 3 networks. A player who succeeds in placing three specific marks in a horizontal, vertical, or diagonal row wins. It is a great way to pass the time by playing Tic Tac Toe. We prepared a simple version for everyone!",

      projectUrl: "https://play.google.com/store/apps/details?id=code.kaalgroup.tictactoe",
      stackList: ["Min Max", "Java", "Native Java", "Material IO", "Firebase", "OneSignal"],
    },

  ],
};

/*=====================
    8. Experience Section
    Desc: This section help you configure your experience.
==========================*/

const experienceSection = {
  experiences: [
    {
      experienceTitle: "WhisperPot",
      experienceUrl: "https://whisperpot.com/",
      experienceDesc:
        "Lets Break The Hidden Secrets!",
      experienceDuration: "WhisperPot",
      experienceTags: ["React js", "Javascript", "Jquery", "FireBase"],
    },
    {
      experienceTitle: "Socialbook",
      experienceDesc:
        "A web application which provides you to share your thoughts and quotes and explore your creativity, You can add your own commentary to texts, share these ideas with others, follow others' comments, and create communities of interactive reader/writers.",
      // Recommended : don't add full date.
      experienceDuration: "Social",
      experienceUrl: "https://socialbook.netlify.app/",
      // Recommended : don't add more than 3 tags.
      experienceTags: ["ReactJs", "MaterialUI", "NodeJs", "MongoDB"],
    },
    {
      experienceTitle: "Dare You",
      experienceUrl: "https://dareyou.netlify.app/",
      experienceDesc:
        "Defeat me if you can!",
      experienceDuration: "I Dare You",
      experienceTags: ["React js", "Javascript", "Jquery"],
    },

    {
      experienceTitle: "Text 2 Handwriting",
      experienceDesc:
        "I Hate writing assignments, so I build this AI tool to convert text into Handwritten & PDF images and you can add your own handwriting and paper's.",
      // Recommended : don't add full date.
      experienceDuration: "Tool",
      experienceUrl: "https://text2handwritten.netlify.app/",
      // Recommended : don't add more than 3 tags.
      experienceTags: ["Reactjs", "HTML5", "CSS3", "SCSS", "Canvas"],
    },
    {
      experienceTitle: "Gazali",
      experienceDesc:
        "E-Commerce Platform for burkah and clothes",
      // Recommended : don't add full date.
      experienceDuration: "E-com",
      experienceUrl: "https://gazali.in/",
      // Recommended : don't add more than 3 tags.
      experienceTags: ["HTML5", "CSS3", "SCSS", "PHP", "MYSQL"],
    },
    {
      experienceTitle: "Kulsum Optics",
      experienceUrl: "https://kulsumoptics.netlify.app/",
      experienceDesc:
        "Ecommerce Website for Optics And Glasses.",
      experienceDuration: "E-com",
      experienceTags: ["React js", "Mongo DB", "Material UI", "Node Js"],
    },
    {
      experienceTitle: "Home Town",
      experienceUrl: "https://homiestown.netlify.app/",
      experienceDesc:
        "Homies Town is an ecommerce Web - application for clothes, and jwellery etc.",
      experienceDuration: "E-com",
      experienceTags: ["React js", "Mongo DB", "Material UI", "Node Js"],
    },
    {
      experienceTitle: "Karate Coach",
      experienceUrl: "https://karatecoach.netlify.app/",
      experienceDesc:
        "Portfolio Website For Karate Coach",
      experienceDuration: "Portfolio",
      experienceTags: ["React js", "CloudFlare", "Material UI", "Node Js"],
    },
    // {
    //   experienceTitle: "Swis Com",
    //   experienceUrl: "https://swiscom.netlify.app/",
    //   experienceDesc:
    //     "Portfolio Website For Karate Coach",
    //   experienceDuration: "Portfolio",
    //   experienceTags: ["React js", "CloudFlare", "Material UI", "Node Js"],
    // },
    {
      experienceTitle: "Pointer Pointer",
      experienceUrl: "https://pointer-pointer.netlify.app/",
      experienceDesc:
        "For Fun ;-)",
      experienceDuration: "Fun",
      experienceTags: ["React js", "Javascript", "Jquery"],
    },
    {
      experienceTitle: "Annoying",
      experienceUrl: "https://annoying.netlify.app/",
      experienceDesc:
        "For Spammers I Dare You to Open Annoying",
      experienceDuration: "I Dare You",
      experienceTags: ["React js", "Javascript", "Jquery"],
    },
    
  ],
};

/*=====================
    9. Contact Section
    Desc: This section help you configure your contact.
==========================*/

const contactSection = {
  contactTagline: "Kaal Group is a fast-growing Mumbai based company, we work on Android Application, Web Application, E-commerce Websites & More.",
  // Note : add your all contacts in the contactList seperating with *,* .
  contactList: ["support@kaalgroup.co.in"],
};

//twitter
const Twitter = {
  username: "kaalpvt"
}

// Bingo :))

export {
  app,
  socialNetworks,
  bannerSection,
  aboutSection,
  skillsSection,
  openSourceSection,
  projectsSection,
  experienceSection,
  contactSection,
  Twitter,
  projectTeams,
};
